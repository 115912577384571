import classnames from 'classnames';

export default function Button({ children, button = false, black = false, ...attributes }) {
  const buttonClassname = classnames(
    "duration-100 ease-in-out focus:border-blue-500 focus:ring-4 focus:ring-blue-500 focus:outline-none focus:ring-opacity-75 px-12 pt-4 pb-3 md:pt-6 md:pb-4 cursor-pointer leading-none flex items-center justify-center uppercase font-semibold rounded-full text-base sm:min-w-button max-w-full",
    {
      "bg-black text-white hover:bg-gray-900": !black,
      "bg-white text-black hover:bg-gray-100 ": black,
    }
  );

  if (button) {
    return <button type="button" className={buttonClassname} {...attributes}>{ children }</button>
  }

  return <a className={buttonClassname} {...attributes}>{ children }</a>
}
