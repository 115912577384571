
import useHeaderVideo from "../hooks/useHeaderVideo";
import Button from "./Button";
import PageContainer from "./PageContainer";
import PageHeadNavigation from "./PageHeadNavigation";

export default function PageHead () {
	const { pageHeadHeight, videoWidth } = useHeaderVideo();

	return (
		<>
			<div
				id="top"
				className="relative page-head items-center justify-center w-full h-full"
				style={{
					height: pageHeadHeight ? `${pageHeadHeight}px` : null
				}}
			>
				<div className="flex flex-col items-center w-full h-full ">
					<PageContainer className="px-8 md:px-8 top-0 left-0 w-full flex justify-end uppercase py-8 text-medium text-black flex flex-row items-center">
						<PageHeadNavigation />
					</PageContainer>

					<div className="flex-grow w-full flex flex-col justify-end items-center z-10 pb-28 md:pb-32">
						<div className="mb-12 md:mb-16 text-center">
							<h1 className="font-bold text-large md:text-superheader text-white text-shadow">Zero to One Origins</h1> 
							<p className="text-medium-2 md:text-large font-semibold text-white text-shadow">Chapter Graphics NFT</p>
						</div>

						<Button href="#getapack">Get a pack</Button>
					</div>
				</div>

				<div
					id="video"
					className="absolute top-0 left-0 z-0 w-full h-full overflow-hidden bg-cover bg-video video-back"
				>
						<div
							className="relative max-h-full h-full"
							style={
								{
									width: `${videoWidth}px`,
									marginLeft: '50%',
									transform: 'translateX(-50%)',
									display: videoWidth ? "block" : "none",
								}
							}
						>
							<div className="absolute top-0 left-0 z-0 object-cover w-full video">
								<iframe
									id="video"
									className="absolute top-0 left-0 z-0 w-full h-full pointer-events-none"
									src="https://player.vimeo.com/video/691594378?autoplay=1&loop=1&title=0&byline=0&portrait=0&api=1&background=1"
									frameBorder="0"
									allow="autoplay; fullscreen; picture-in-picture"
									allowFullScreen
									title="Zero to One Origins: Chapter Graphics NFT"
									data-ready="true"
								/>
							</div>
						</div>
				</div>
			</div>

			<p className="relative z-20 flex justify-center mb-16 -mt-16">
				<a href="#story" className="text-white hover:text-gray-200"><svg className="hidden lg:block" width="52" height="34"
				viewBox="0 0 52 34" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd"
					d="M28.159 32.1394C27.8755 32.4233 27.5387 32.6485 27.1681 32.8022C26.7974 32.9558 26.4001 33.0349 25.9989 33.0349C25.5976 33.0349 25.2003 32.9558 24.8296 32.8022C24.459 32.6485 24.1222 32.4233 23.8387 32.1394L0.924325 9.21362C0.634328 8.93134 0.403291 8.59426 0.244629 8.22196C0.0859665 7.84966 0.00283917 7.44956 7.14668e-05 7.04487C-0.00269624 6.64018 0.0749509 6.23897 0.228506 5.86454C0.382062 5.4901 0.608465 5.1499 0.894575 4.86368C1.18068 4.57746 1.5208 4.35092 1.89517 4.19722C2.26955 4.04352 2.67073 3.96572 3.07542 3.96833C3.4801 3.97094 3.88025 4.05391 4.25261 4.21243C4.62497 4.37095 4.96213 4.60185 5.24452 4.89174L25.9987 25.6563L46.7529 4.89174C47.0352 4.60133 47.3723 4.36993 47.7448 4.21099C48.1173 4.05205 48.5177 3.96874 48.9227 3.9659C49.3277 3.96305 49.7292 4.04074 50.1039 4.19444C50.4786 4.34814 50.819 4.57479 51.1053 4.86121C51.3916 5.14762 51.6182 5.4881 51.7717 5.86284C51.9253 6.23758 52.0029 6.63911 51.9999 7.04409C51.9969 7.44907 51.9135 7.84942 51.7544 8.22187C51.5954 8.59431 51.3639 8.93143 51.0734 9.21362L28.159 32.1394Z"
					fill="black"></path>
				<path fillRule="evenodd" clipRule="evenodd"
					d="M28.159 29.0847C27.8755 29.3686 27.5387 29.5938 27.1681 29.7475C26.7974 29.9011 26.4001 29.9802 25.9989 29.9802C25.5976 29.9802 25.2003 29.9011 24.8296 29.7475C24.459 29.5938 24.1222 29.3686 23.8387 29.0847L0.924325 6.15893C0.634328 5.87665 0.403291 5.53958 0.244629 5.16728C0.0859665 4.79498 0.00283917 4.39487 7.14668e-05 3.99018C-0.00269624 3.58549 0.0749509 3.18429 0.228506 2.80985C0.382062 2.43542 0.608465 2.09521 0.894575 1.80899C1.18068 1.52277 1.5208 1.29623 1.89517 1.14253C2.26955 0.988833 2.67073 0.91103 3.07542 0.91364C3.4801 0.916251 3.88025 0.999224 4.25261 1.15774C4.62497 1.31626 4.96213 1.54716 5.24452 1.83705L25.9987 22.6016L46.7529 1.83705C47.0352 1.54664 47.3723 1.31524 47.7448 1.1563C48.1173 0.997361 48.5177 0.914049 48.9227 0.911208C49.3277 0.908367 49.7292 0.986055 50.1039 1.13976C50.4786 1.29345 50.819 1.5201 51.1053 1.80652C51.3916 2.09294 51.6182 2.43341 51.7717 2.80815C51.9253 3.18289 52.0029 3.58442 51.9999 3.9894C51.9969 4.39438 51.9135 4.79473 51.7544 5.16718C51.5954 5.53963 51.3639 5.87674 51.0734 6.15893L28.159 29.0847Z"
					fill="currentColor"></path>
			</svg><svg className="lg:hidden" width="35" height="22" viewBox="0 0 35 22" fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd"
					d="M18.4388 20.4483C18.2531 20.6342 18.0326 20.7817 17.7899 20.8823C17.5472 20.9829 17.287 21.0347 17.0243 21.0347C16.7615 21.0347 16.5014 20.9829 16.2586 20.8823C16.0159 20.7817 15.7954 20.6342 15.6098 20.4483L0.605255 5.4363C0.415363 5.25146 0.264078 5.03074 0.160185 4.78696C0.0562915 4.54317 0.00185911 4.28118 4.6797e-05 4.01619C-0.00176552 3.75119 0.0490784 3.48848 0.149628 3.2433C0.250177 2.99811 0.398428 2.77535 0.585774 2.58793C0.773121 2.40051 0.99583 2.25217 1.24097 2.15152C1.48612 2.05088 1.74881 1.99993 2.01381 2.00164C2.2788 2.00335 2.54082 2.05768 2.78464 2.16148C3.02847 2.26528 3.24924 2.41648 3.43416 2.6063L17.0242 16.2031L30.6142 2.6063C30.799 2.41614 31.0198 2.26462 31.2637 2.16054C31.5076 2.05646 31.7698 2.00191 32.035 2.00005C32.3002 1.99819 32.5631 2.04906 32.8084 2.1497C33.0538 2.25035 33.2767 2.39876 33.4642 2.58631C33.6516 2.77385 33.8 2.9968 33.9006 3.24218C34.0011 3.48756 34.0519 3.75049 34.05 4.01567C34.048 4.28086 33.9934 4.54301 33.8892 4.78689C33.7851 5.03077 33.6335 5.25152 33.4433 5.4363L18.4388 20.4483Z"
					fill="black"></path>
				<path fillRule="evenodd" clipRule="evenodd"
					d="M18.4388 18.4483C18.2531 18.6342 18.0326 18.7817 17.7899 18.8823C17.5472 18.9829 17.287 19.0347 17.0243 19.0347C16.7615 19.0347 16.5014 18.9829 16.2586 18.8823C16.0159 18.7817 15.7954 18.6342 15.6098 18.4483L0.605255 3.4363C0.415363 3.25146 0.264078 3.03074 0.160185 2.78696C0.0562915 2.54317 0.00185911 2.28118 4.6797e-05 2.01619C-0.00176552 1.75119 0.0490784 1.48848 0.149628 1.2433C0.250177 0.998113 0.398428 0.775346 0.585774 0.587927C0.773121 0.400507 0.99583 0.252168 1.24097 0.151523C1.48612 0.0508786 1.74881 -6.74162e-05 2.01381 0.00164188C2.2788 0.00335117 2.54082 0.0576829 2.78464 0.161482C3.02847 0.26528 3.24924 0.416478 3.43416 0.606298L17.0242 14.2031L30.6142 0.606298C30.799 0.416138 31.0198 0.264615 31.2637 0.160539C31.5076 0.0564631 31.7698 0.00190961 32.035 4.92419e-05C32.3002 -0.00181113 32.5631 0.0490599 32.8084 0.149704C33.0538 0.250347 33.2767 0.398757 33.4642 0.586305C33.6516 0.773854 33.8 0.996798 33.9006 1.24218C34.0011 1.48756 34.0519 1.75049 34.05 2.01567C34.048 2.28086 33.9934 2.54301 33.8892 2.78689C33.7851 3.03077 33.6335 3.25152 33.4433 3.4363L18.4388 18.4483Z"
					fill="currentColor"></path>
			</svg></a>
		</p>
		</>
	)
}
