import { Link } from "react-router-dom";
import Paragraph from "./Paragraph";

export default function Navigation ({ showHome = false }) {
  return <Paragraph className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-10 mx-auto justify-center items-center pt-24">
    {showHome && <Link className='underline text-black hover:text-gray-800' to="/">← Home</Link>}
    <Link className='underline text-black hover:text-gray-800' to="/privacy-policy">Privacy Policy</Link>
    <Link className='underline text-black hover:text-gray-800' to="/terms-and-conditions">Terms and conditions</Link>
  </Paragraph>
}

