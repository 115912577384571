import { useCallback, useEffect, useState } from "react";
import { isTouchDevice } from "../helpers";

const useHeaderVideo = () => {
  const videoW = 16;
	const videoH = 10;
	const [resizeTimeout, setResizeTimeout] = useState(null);
	const [pageHeadHeight, setPageHeadHeight] = useState(null);
	const [videoWidth, setVideoWidth] = useState(null);
	
	const calculateWidth = useCallback(() => {
		setPageHeadHeight(window.innerHeight);
		
		const videoWrapper = document.querySelector('#video');
    if (!videoWrapper) {
			return;
    }
		const containerHeight = videoWrapper.offsetHeight;
		
		const containerWidth = videoWrapper.offsetWidth;
		const width = (videoW / videoH * containerHeight);
		if (width < containerWidth) {
			setVideoWidth(containerWidth + 30);
		} else {
			setVideoWidth(width + 30);
		}
	}, [setPageHeadHeight, setVideoWidth]);



	const doRecalculate = useCallback(() => {
		if (resizeTimeout) {
			clearTimeout(resizeTimeout)
		}

		setResizeTimeout(setTimeout(() => {
			setResizeTimeout(null)
			
			calculateWidth();
		}, 250));

	}, [resizeTimeout, setResizeTimeout, calculateWidth])

  const resizeEventListener =   () => {
    doRecalculate();
  }

	useEffect((() => {
    calculateWidth();

		setTimeout(() => {
			calculateWidth();
		}, 500);
    
    if (isTouchDevice()) {
      return;
    }
    
    window.addEventListener('resize', resizeEventListener);

    return () => {
      window.removeEventListener('resize', resizeEventListener);
    }
	}), []);

  return { videoWidth, pageHeadHeight }
};

export default useHeaderVideo;