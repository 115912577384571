import React, { useEffect } from 'react';
import Header from '../components/Header'
import Header2 from '../components/Header2'
import Container from '../components/Container'
import NFTSelector from '../components/NFTSelector'
import Paragraph from '../components/Paragraph'
import Anchor from '../components/Anchor'
import PageHead from '../components/PageHead'
import PageContainer from '../components/PageContainer';
import NFTList from '../components/NFTList';
import NFTAmount from '../components/NFTAmount';
import Navigation from '../components/Navigation';

const Home = () => {
	const nfts  = {
		ultrarare: ['rocket', 'pirate', 'buddha'],
		rare: ['key', 'chess', 'car', 'boat', 'root', 'fox', 'globe', 'numpad', 'teacher'],
		common: ['hal9000', 'quijote', 'adn'],
	}

	useEffect(() => {
		document.querySelectorAll('a[href^="#"]').forEach(anchor => {
			anchor.addEventListener('click', function (e) {
					e.preventDefault();
	
					document.querySelector(this.getAttribute('href')).scrollIntoView({
							behavior: 'smooth'
					});
			});
	});
	}, [])

	return(
		<React.Fragment>
			<div className="pb-24 text-black">
				<PageHead />

				<Container id="story" className="md:pt-12">
					<div className='py-8'>
						<Header>
							Save America, Win Big Prizes
						</Header>
					</div>

					<Paragraph>
						My <Anchor href="https://v1.ztonft.com" target="_blank">Zero to One Origins NFT</Anchor> sold out in less than 36 hours, raising more than $550k for my U.S. Senate campaign in Arizona. 
					</Paragraph>

					<Paragraph>
						But there weren’t enough NFT’s for everyone who wanted one, and I know not everyone can afford to donate $5,800.
					</Paragraph>
					<Paragraph>
						To allow more people to join the club, we’re launching Version 2: more NFTs, with many more chances to win — based on original Zero to One art nobody has ever seen before.
					</Paragraph>
					<Paragraph>
						When prototyping Zero to One, we wanted to include these simple and beautiful illustrations at the beginning of each chapter. But it would have made the book more expensive to buy, so we dropped the idea.
					</Paragraph>
					
					<div className="mb-4 md:mb-8 md:px-6 py-10 flex flex-col items-center"><img width={1023} height={655} alt="" className='max-w-full mx-auto' src='images/book.png' /></div>

					<Paragraph>
						Now we are releasing 20,000 NFT’s based on these original graphics. And we’ve made it fun. Some of the NFTs are more rare than others. And some are super rare.
					</Paragraph>
					<Paragraph>
					You can get a single NFT, or you can get packs of them to increase your chances of getting the best ones. The minting site will go live in May or June 2022, and you never know which NFTs you’re going to get when you hit “mint”.
					</Paragraph>
					<Paragraph>
						What you do know is that there are some really cool prizes if you get a little lucky — check out the Collection and read more below. 
					</Paragraph>

					<div className="mb-4 md:mb-8 md:px-6 py-10 flex flex-col items-center">
						<p className='mb-8'><img className='max-w-full mx-auto' src='images/signature.svg' /></p>

						<Paragraph className="text-center"><i>Blake Masters, Co-author of Zero to One</i></Paragraph>
					</div>

				</Container>
				
				<NFTSelector id="getapack" />

				<div className='bg-black text-white mt-20 py-12' id="prizes">
					<Header>
						The Prizes
					</Header>

					<PageContainer>
						<Container>
						<Paragraph className="text-center">
							Some of the chapter graphics are more rare than others — see <a className='underline text-white hover:text-gray-100' href="#collection">The Collection</a> below. If you get lucky and mint a rare one, that’s a prize in itself!
						</Paragraph>
						</Container>

						<div className='flex justify-between mt-16 md:mt-20 flex-col md:flex-row'>
							<div className='md:w-1/2 md:pr-20 flex justify-center'>
								<div><img src='images/prize_lobby.jpeg' width={571} height={571}  alt="" className='max-w-full scale-90' /></div>
							</div>
							<div className='md:w-1/2 pt-8'>
								<Header2>
									Lobby
								</Header2>

								<Paragraph className="font-semibold">All Zero to One NFT holders get:</Paragraph>

								<ul className='pl-6 md:pl-14 list-disc'>
									<li className='text-medium md:text-base mb-4'>Access to the Zero to One NFT Discord lobby</li>
									<li className='text-medium md:text-base mb-4'>Shot at attending Zero to One NFT holders parties (upper tiers get priority access)</li>
									<li className='text-medium md:text-base mb-4'>Opportunity to burn common NFTs into something more rare</li>
								</ul>
							</div>
						</div>

						<div className='flex justify-between mt-16 md:mt-20 flex-col md:flex-row md:flex-row-reverse'>
							<div className='md:w-1/2 md:pl-20 flex justify-center'>
								<div><img src='images/prize_gold.jpeg' width={571} height={571}  alt="" className='max-w-full scale-90' /></div>
							</div>

							<div className='md:w-1/2 pt-8'>
								<Header2>
									Gold Club
								</Header2>

								<Paragraph className="font-semibold">10% of each chapter graphic NFTs are gold. If you get lucky and mint a gold chapter, you’ll receive:</Paragraph>

								<ul className='pl-6 md:pl-14 list-disc'>
									<li className='text-medium md:text-base mb-4'>A beautiful foil pressed signed art print of your gold chapter graphic</li>
									<li className='text-medium md:text-base mb-4'>Access to the Gold Club NFT Discord channel </li>
									<li className='text-medium md:text-base mb-4'>A copy of Zero to One, signed and personalized by Blake (one per person) </li>
									<li className='text-medium md:text-base mb-4'>Early access to party RSVP signup</li>
								</ul>
							</div>
						</div>

						<div className='flex justify-between mt-16 md:mt-20 flex-col md:flex-row'>
							<div className='md:w-1/2 md:pr-20 flex justify-center'>
								<div><img src='images/prize_surprise.jpeg' width={571} height={571}  alt="" className='max-w-full scale-90' /></div>
							</div>
							<div className='md:w-1/2 pt-8'>
								<Header2>
									Mystery Lounge
								</Header2>

								<Paragraph className="font-semibold">If you collect 25 non-gold NFTs, you can choose to burn them and receive:</Paragraph>

								<ul className='pl-6 md:pl-14 list-disc'>
									<li className='text-medium md:text-base mb-4'>Two nice, non-gold letterpress prints</li>
									<li className='text-medium md:text-base mb-4'>A Special Mystery Lounge NFT</li>
									<li className='text-medium md:text-base mb-4'>A Blake Masters for Senate t-shirt</li>
									<li className='text-medium md:text-base mb-4'>Access to the Mystery Lounge NFT Discord channel</li>
									<li className='text-medium md:text-base mb-4'>A copy of Zero to One, signed and personalized by Peter and Blake (one per person) </li>
									<li className='text-medium md:text-base mb-4'>Early access to party RSVP signup</li>
								</ul>
							</div>
						</div>


						<div className='flex justify-between mt-16 md:mt-20 flex-col md:flex-row md:flex-row-reverse'>
							<div className='md:w-1/2 md:pl-20 flex justify-center'>
								<div><img src='images/prize_book.jpeg' width={571} height={571}  alt="" className='max-w-full scale-90' /></div>
							</div>
							
							<div className='md:w-1/2 pt-8'>
								<Header2>
									Rocket boys
								</Header2>

								<Paragraph className="font-semibold">If you collect all 15 gold chapter graphics NFTs you get:</Paragraph>

								<ul className='pl-6 md:pl-14 list-disc'>
									<li className='text-medium md:text-base mb-4'>A private dinner with Blake and Peter Thiel (with a $2,000 travel allowance)</li>
									<li className='text-medium md:text-base mb-4'>A mention in the Acknowledgments section in future printings of Zero to One (if any)</li>
									<li className='text-medium md:text-base mb-4'>A custom, one of only ten, numbered, handmade physical copies of the original vision of the book, personalized by Blake and Peter (pictured to the right)</li>
									<li className='text-medium md:text-base mb-4'>Access to the ultra exclusive Rocket Boys Discord channel</li>
									<li className='text-medium md:text-base mb-4'>VIP access to any ZtO parties</li>
								</ul>
							</div>
						</div>
					</PageContainer>

				<div className='mt-20 md:mt-0'>
						<NFTSelector black />
					</div>
				</div>

				<Container className="pt-24" id="collection">
					<Header>
						The Collection
					</Header>
					<div className='mb-4 text-medium text-center md:text-medium-2 md:mb-8 '>
						20,000 total NFTs<br />
						10% of each chapter are gold, with <Anchor href="#prizes">extra prizes</Anchor>
					</div>

					<div>
						<p><img className='max-w-full mx-auto scale-90' src='images/ultrarare.svg' /></p>

						<div className='grid lg:grid-cols-3 gap-16 py-12'>
							<NFTAmount amount="100" label="Rockets" />
							<NFTAmount amount="400" label="Pirate Flags" />
							<NFTAmount amount="600" label="Buddhas" />
						</div>

						<Paragraph className="uppercase text-center pb-12"><span className='font-semibold'>10% OF EACH ARE GOLD</span></Paragraph>

						<NFTList nfts={nfts['ultrarare']} />
					</div>


					<div>
						<p><img className='max-w-full mx-auto scale-90' src='images/rare.svg' /></p>

						<div className='grid lg:grid-cols-2 gap-16 py-12 mx-auto lg:max-w-nft-count-2'>
							<NFTAmount amount="9,000" label="Total" />
							<NFTAmount amount="1,000" label="of each graphic" />
						</div>

						<Paragraph className="uppercase text-center pb-12"><span className='font-semibold'>10% OF EACH ARE GOLD</span></Paragraph>

						<NFTList nfts={nfts['rare']} />
					</div>
					

					<div>
						<p><img className='max-w-full mx-auto scale-90' src='images/common.svg' /></p>

						<div className='grid lg:grid-cols-2 gap-16 py-12 mx-auto lg:max-w-nft-count-2'>
							<NFTAmount amount="9,900" label="Total" />
							<NFTAmount amount="3,300" label="of each graphic" />
						</div>

						<Paragraph className="uppercase text-center pb-12"><span className='font-semibold'>10% OF EACH ARE GOLD</span></Paragraph>

						<NFTList nfts={nfts['common']} />
					</div>
				</Container>


				<NFTSelector />
				<br/>

				<Container className="pt-12 md:pt-0">
					<Header>
						Campaign Finance Rules
					</Header>
					
					<Paragraph>Contributions to Blake Masters for Senate are not tax-deductible as charitable contributions.</Paragraph>

					<Paragraph>The maximum amount an individual may contribute is $2,900 per election.</Paragraph>

					<Paragraph>Your contribution (up to $2,900) will be designated for the primary election.</Paragraph>

					<Paragraph>The next $2,900 will be designated for the general election. Federal multicandidate PACs may contribute up to $5,000 per election.</Paragraph>

					<Paragraph>Contributions from corporations, labor unions, federal contractors, and foreign nationals (non-green card holders) are prohibited.</Paragraph>

					<Paragraph>Contributions must be made from personal funds and may not be reimbursed by any other person.</Paragraph>

					<Paragraph>Federal law requires us to use our best efforts to obtain and report the name, mailing address, occupation, and name of employer for each individual whose contributions aggregate in excess of $200 in an election cycle.</Paragraph>

					<Paragraph className="md:py-12 py-12 text-center"><Anchor href="mailto:NFT@blakemasters.com" className="text-medium md:text-medium-large">NFT@blakemasters.com</Anchor></Paragraph>

					<img src='images/paidfor.svg' className='max-w-full mx-auto md:px-48' />

					<Navigation />

				</Container>
			</div>
		</React.Fragment>
	)
}

export default Home;
