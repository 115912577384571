import React from 'react'
import { Route, BrowserRouter, Routes } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import { Home, Terms, Privacy } from './pages';

function App() {
    return (
        <React.Fragment>
            <BrowserRouter>
                <ScrollToTop>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/privacy-policy" element={<Privacy />} />
                        <Route path="/terms-and-conditions" element={<Terms />} />
                    </Routes>
                </ScrollToTop>
            </BrowserRouter>
       </React.Fragment>
    );
}

export default App;
