
import React from 'react';
import Header from '../components/Header'
import Container from '../components/Container'
import Navigation from '../components/Navigation';

const Terms = () => {
	return(
		<React.Fragment>
			<Container className="py-24 md:pt-0 text-small md:text-base">
				<Header>
					Terms and conditions
				</Header>
				<p><strong><u>ROCKET BOY NFT SWEEPSTAKES OFFICIAL RULES</u></strong></p>
<p><strong>Important: </strong>By participating in Rocket Boy NFT Sweepstakes (the &ldquo;Promotion&rdquo;), you agree to be bound by these Official Rules and represent that you satisfy all eligibility requirements.&nbsp; The Promotion is void where prohibited or restricted by law.&nbsp;</p>
<p>&nbsp;</p>
<p><strong>NO PURCHASE, PAYMENT, OR CONTRIBUTION OF ANY KIND IS NECESSARY TO ENTER OR TO WIN A PRIZE.&nbsp; A PURCHASE, PAYMENT OR CONTRIBUTION WILL NOT INCREASE YOUR ODDS OF WINNING.&nbsp; </strong></p>
<p><strong>&nbsp;</strong></p>
<ol>
<li><strong><u>1. Eligibility</u>:</strong> The Promotion is open only to citizens and legal permanent residents of the United States who are at least eighteen (18) years of age or the legal age of majority in their state of residence, whichever is older. Directors, officers, employees, and contractors of Blake Masters for Senate (the &ldquo;Sponsor&rdquo;) and any immediate family members of each are not eligible to win a prize.&nbsp; ALL APPLICABLE FEDERAL, STATE AND LOCAL LAWS AND REGULATIONS APPLY.</li>
</ol>
<p>&nbsp;</p>
<ol start="2">
<li><strong><u>2. Prize Description</u></strong><strong>:</strong> Sponsor will issue up to twenty thousand (20,000) nonfungible tokens, based on the number of entries received by the deadline. Each nonfungible token will be referred to hereafter as an &ldquo;NFT&rdquo; and, together, they will be referred to as the NFT Population. The NFT Population includes fifteen (15) different images, based on unreleased prototype art for the book <em>Zero to One</em>.&nbsp; Additionally, there is a standard (red) version and a gold version of each image. For every 9 standard versions of an image, there will be one gold version of the image.&nbsp; Some of the images are more common than others; the number of each image in the NFT Population, and the odds of randomly minting any given image, are as follows:</li>
</ol>
<p>&nbsp;</p>
<ul className='pl-6 list-disc'>
<li>10 gold Rockets (0.05% of the NFT Population)</li>
<li>90 standard Rockets (0.45% of the NFT Population)</li>
<li>40 gold Pirate Flags (0.2 % of the NFT Population)</li>
<li>360 standard Pirate Flags (1.8% of the NFT Population)</li>
<li>60 gold Buddhas (0.3% of the NFT Population)</li>
<li>540 standard Buddhas (2.7% of the NFT Population)</li>
<li>100 gold Keys (0.5% of the NFT Population)</li>
<li>900 standard Keys (4.5% of the NFT Population)</li>
<li>100 gold Chess Masters (0.5% of the NFT Population)</li>
<li>900 standard Chess Masters (4.5% of the NFT Population)</li>
<li>100 gold DeLoreans (0.5% of the NFT Population)</li>
<li>900 standard DeLoreans (4.5% of the NFT Population)</li>
<li>100 gold Rising Tides (0.5% of the NFT Population)</li>
<li>900 standard Rising Tides (4.5% of the NFT Population)</li>
<li>100 gold Deep Roots (0.5% of the NFT Population)</li>
<li>900 standard Deep Roots (4.5% of the NFT Population)</li>
<li>100 gold Hedgehog and Foxes (0.5% of the NFT Population)</li>
<li>900 standard Hedgehog and Foxes (4.5% of the NFT Population)</li>
<li>100 gold Bursting Bubbles (0.5% of the NFT Population)</li>
<li>900 standard Bursting Bubbles (4.5% of the NFT Population)</li>
<li>100 gold 13<sup>th</sup> Floors (0.5% of the NFT Population)</li>
<li>900 standard 13<sup>th</sup> Floors (4.5% of the NFT Population)</li>
<li>100 gold Salesmen (0.5% of the NFT Population)</li>
<li>900 standard Salesmen (4.5% of the NFT Population)</li>
<li>330 gold Hal 9000s (1.65% of the NFT Population)</li>
<li>2,970 standard Hal 9000s (14.85% of the NFT Population)</li>
<li>330 gold Don Quixotes (1.65% of the NFT Population)</li>
<li>2,970 standard Don Quixotes (14.85% of the NFT Population)</li>
<li>330 gold Double Helixes (1.65% of the NFT Population)</li>
<li>2,970 standard Double Helixes (14.85% of the NFT Population)</li>
</ul>
<p>&nbsp;</p>
<p>All NFTs will be assigned on a random basis to participants who submit entries in accordance with these Official Rules. Specifically, Sponsor will either mint the nonfungible tokens on a random basis and send them (again, on a random basis in accordance with these Official Rules) to each participant who submits one or more valid entries; or Sponsor will provide a website where participants who submits one or more valid entries may go to &ldquo;mint&rdquo; the NFTs to which they are entitled. Sponsor will issue the NFTs or provide the website whereby participants can mint the NFTs before July 1, 2022.&nbsp; Notification of the issuance of the NFTs, or the participants&rsquo; ability to mint the NFTs, will be provided to the email addresses provided by participants.</p>
<p>&nbsp;</p>
<p>The odds stated above and below are based on the NFT Population as a whole.&nbsp; Because the number of each type of NFT is capped at the numbers specified above, if the NFTs are minted over time the odds of minting a given NFT at any point in time (<em>i.e.</em>, among the then-remaining, unminted NFTs) will vary based on the size and contents of the unminted portion of the NFT Population at that moment.</p>
<p>&nbsp;</p>
<p>Available prizes are as follows (each a &ldquo;<u>Prize</u>&rdquo; and, collectively, the &ldquo;<u>Prizes</u>&rdquo;):</p>
<p>&nbsp;</p>
<ul className='pl-6 list-disc'>
<li><strong>Lobby Prize</strong>
<ul className='pl-6 list-disc'>
<li><em>Eligibility</em>: All holders of one or more NFT.</li>
<li><em>Description</em>: Access to the <em>Zero to One</em> NFT Discord lobby; eligibility for possible future invitations to <em>Zero to One </em>NFT holders&rsquo; parties (with additional eligibility criteria for invitations to be determined at a future time); and an opportunity to burn common NFTs into more rare NFTs.</li>
<li><em>Estimated Retail Value</em>: $10</li>
<li><em>Odds of Winning</em>: 100%, upon proper submission of a valid entry.</li>
</ul>
</li>
</ul>
<p>&nbsp;</p>
<ul className='pl-6 list-disc'>
<li><strong>Gold Club</strong>
<ul className='pl-6 list-disc'>
<li><em>Eligibility</em>: All holders of one or more gold NFTs.</li>
<li><em>Description</em>: Lobby Prize; foil-pressed signed art print of the winner&rsquo;s gold NFT; and access to the Gold Club NFT Discord channel; a hardcover copy of <em>Zero to One </em>signed and personalized by Blake Masters.</li>
<li><em>Estimated Retail Value</em>: $40</li>
<li><em>Odds of Winning</em>: Each NFT minted has a 10% chance of being a gold NFT. Therefore, with 1 NFT minted, the odds are 10%. With 8 NFTs minted, the odds are 56.96%. With 30 NFTs minted, the odds are 95.77%. With 60 NFTs minted, the odds are 99.82%.</li>
</ul>
</li>
</ul>
<p>&nbsp;</p>
<ul className='pl-6 list-disc'>
<li><strong>Mystery Lounge</strong>
<ul className='pl-6 list-disc'>
<li><em>Eligibility</em>: All holders of 25 or more standard (non-gold) NFTs.</li>
<li><em>Description</em>: Lobby Prize; letter-pressed signed art prints of two of the winner&rsquo;s NFTs (chosen by Sponsor); access to the Mystery Lounge NFT Discord channel; a Special Mystery Lounge NFT; a Blake Masters for Senate T-shirt; a hardcover copy of <em>Zero to One </em>signed by Peter Thiel and Blake Masters and personalized by Blake Masters.</li>
<li><em>Estimated Retail Value</em>: $100</li>
<li><em>Odds of Winning:</em> The odds vary based on the number of NFTs minted.&nbsp; For example, with fewer than 15 NFTs minted, the odds are 0%. <em>&nbsp;</em>With 25 NFTs minted, the odds are 7.17%. With 30 NFTs minted, the odds are 92.68%.&nbsp; With 60 NFTs minted, the odds are 99.9%.</li>
</ul>
</li>
</ul>
<p>&nbsp;</p>
<ul className='pl-6 list-disc'>
<li><strong>Rocket Boys</strong>
<ul className='pl-6 list-disc'>
<li><em>Eligibility</em>: All holders of all 15 variants of gold NFTs.</li>
<li><em>Description</em>: Mystery Lounge Prize; a mention in the &ldquo;Acknowledgments&rdquo; section of future printings of <em>Zero to One</em> (if any); access to the Rocket Boys Discord channel; one of the ten handmade physical copies of the original vision of <em>Zero to One</em>, with personalized inscriptions from Blake Masters and Peter Thiel; a private (3 person) dinner with Blake Masters and Peter Thiel at a restaurant to be determined by Sponsor in or near Los Angeles, CA between May 1, 2022 and September 1, 2022, and a $2,000 travel allowance, paid in cash to the winner. The Sponsor will not be responsible for any cancellations, delays, diversions or substitutions, or any other act or omissions whatsoever, by transportation carriers, hotels, venue operators, or any other persons providing any prize-related services or accommodations.</li>
<li><em>Estimated Retail Value</em>: $2,250</li>
<li><em>Odds of Winning: </em>he odds of minting gold versions of all 15 images vary based on the number of NFTs minted.  For example, with fewer than 15 NFTs minted, the odds are 0%. With 15 NFTs minted, the odds are 0.00000000000000000000953%. With 60 NFTs minted, the odds are 0.00000000000000000003812%.</li>
</ul>
</li>
</ul>
<p>All costs and expenses associated with acceptance or use of a Prize not specified herein as being provided by Sponsor are the sole responsibility of winners.&nbsp;</p>
<p>&nbsp;</p>
<ol start="3">
<li><strong><u>3. Entry Guidelines</u></strong><strong>:</strong> There are three ways to enter the Promotion:</li>
</ol>
<p>&nbsp;</p>
<p className='pl-6'>&nbsp;(a) <u>Contribution Method</u>: To enter by making a contribution, go to <a href="http://www.ZtONFT.com">www.ZtONFT.com</a>, select the desired contribution level, and follow the instructions on the contribution page.&nbsp; Paid entries must be received by Sponsor no later than March 31, 2022 at 11:59 p.m., Arizona time.&nbsp; At Sponsor&rsquo;s sole discretion, Sponsor may elect to extend this Promotion for one additional month, in which case paid entires must be received no later than April 30, 2022 at 11:59 p.m., Arizona time.&nbsp; Sponsor will issue not more than an aggregate total of 60 NFTs (including both purchased and free NFTs) to each participant, provided that aggregate contributions to the Sponsor by a participant, whether in connection with this Promotion or otherwise, cannot exceed a maximum of $2,900 for each of the 2022 primary election and general election.&nbsp; <strong>Contributions to Blake Masters for U.S. Senate are not deductible for federal income tax purposes.</strong>&nbsp; Contributions from corporations, labor unions, federal contractors, national banks, and foreign nationals without green cards are prohibited.&nbsp; Federal law requires the Sponsor&rsquo;s best efforts to collect and report the name, mailing address, occupation, and name of the employer of individuals whose aggregate contributions exceed $200 in an election cycle.&nbsp; Requests or contributions may not be submitted in the name of a third party, and contributions may not be reimbursed by any other person. Contributions made in connection with the 2022 general election will be refunded if Blake Masters is not the Republican nominee for the office of U.S. Senator, as determined in the August 2, 2022 Arizona primary election.&nbsp; <strong>Making a contribution will not increase your chances of winning</strong>.</p>
<p>&nbsp;</p>
<p className='pl-6'>&nbsp;(b) <u>Alternate Method of Entry</u>: To enter without making a purchase or contribution, mail a handwritten, self-addressed envelope, postage prepaid, and a handwritten request for a NFT that includes your name, mailing address, email address, and Ethereum address to: Blake Masters for Senate, P.O. Box 13009, Tucson, Arizona 85732-3009.&nbsp; Each request submitted in compliance with these Official Rules will be honored by the issuance of one NFT.&nbsp; There is a limit of one entry per envelope, and participants desiring to obtain more than one NFT must submit a separate handwritten request and a separate self-addressed envelope, postage prepaid, for each NFT requested. No typewritten, mechanically reproduced or photocopied requests will be accepted. Residents of Washington or Vermont may omit return postage.&nbsp; Sponsor will issue not more than an aggregate total of 60 NFTs (including both purchased and free NFTs) to each participant.&nbsp; Requests may not be submitted in the name of a third party. Requests must be postmarked no later than March 31, 2022 and received by Sponsor no later than April 5, 2022 at 11:59 p.m., Arizona time.&nbsp; If Sponsor elects to extend the Promotion through April 2022, Requests must be postmarked no later than April 30, 2022 and received by Sponsor no later than May 5, 2022 at 11:59 p.m., Arizona time.&nbsp;</p>
<p>&nbsp;</p>
<p className='pl-6'>&nbsp;(c)&nbsp; <u>Promotional Gifts</u>: For purposes of marketing, promotion, and public relations, the Sponsor may give NFT minting rights to persons selected in the Sponsor&rsquo;s sole and unlimited discretion, subject to the eligibility requirements set forth in these Official Rules.</p>
<p>&nbsp;</p>
<p>For all entry methods, any violation of, or non-compliance with, these Official Rules, including but not limited to failure to submit all required information in the manner required in these Official Rules and/or in any contribution or entry form, may result in disqualification.&nbsp; Receipt of entries will not be confirmed by Sponsor, and proof of contribution or online entry will not be deemed proof of receipt of entry by Sponsor.&nbsp; Sponsor is not responsible for late, lost, misdirected, damaged, altered, illegible, mutilated, undelivered, or postage-due entries.&nbsp; By entering the Promotion, you consent to being placed on Sponsor&rsquo;s email list, but may opt-out at any time.&nbsp; Information collected by the Sponsor from participants will be governed by Sponsor&rsquo;s privacy policy (as amended by Sponsor from time to time, in its sole discretion), which is available at: <a href="https://www.blakemasters.com/privacy">https://www.blakemasters.com/privacy</a>.&nbsp;</p>
<p>&nbsp;</p>
<ol start="4">
<li><strong><u>4. Entry Period</u></strong><strong>:</strong> The Promotion will begin on March 24, 2022. All entries must be received by the deadlines set forth in Section 3 or prior to such time as the supply of NFTs is exhausted, whichever occurs first.</li>
</ol>
<p>&nbsp;</p>
<ol start="5">
<li><strong><u>5. Winner Restrictions</u></strong><strong>: </strong>As a condition of receipt of a Prize, each winner may be required to provide to the Sponsor (a) documentation and/or information establishing proof of identity and of eligibility under all applicable federal, state and local laws to receive a Prize; (b) a liability release; (c) a publicity release giving Sponsor permission to use the winner&rsquo;s name, hometown, likeness and/or Prize information, without limitation, for promotional purposes without further permission or compensation; (d) an Ethereum address (or similar crypto address, should Sponsor decide to utilize a different protocol for the NFTs); and (e) any other documents or information reasonably required by Sponsor to verify compliance with these Official Rules or with any applicable law.&nbsp; Winners may claim a Prize by sending proof of eligibility by email to <a href="mailto:NFT@blakemasters.com">NFT@blakemasters.com</a> or by mail to: Blake Masters for Senate, P.O. Box 13009, Tucson, Arizona 85732-3009.&nbsp; All claims of Prizes must be received no later than 30 days after Sponsor notifies participants that the Sponsor has minted the NFTs or of the participants&rsquo; ability to mint the NFTs.&nbsp; Prizes not claimed by this deadline will be deemed forfeited.&nbsp; For purposes of determining the date of notification, Sponsor&rsquo;s internal records reflecting the date of electronic transmittal of the notification will be final and conclusive.</li>
</ol>
<p>&nbsp;</p>
<ol start="6">
<li><strong><u>6. Taxes</u></strong><strong>:</strong> All federal, state and local taxes associated with the receipt or use of a Prize are the sole responsibility of the winners.</li>
</ol>
<p>&nbsp;</p>
<ol start="7">
<li><strong><u>7. Prize Restrictions</u></strong><strong>:</strong> <strong>All Prizes are awarded as-is with no warranties of any kind, express or implied (including without limitation any implied warranty of merchantability or implied warranty of fitness for a particular purpose)</strong>. Although the NFTs are transferrable, the Prizes are non-transferrable.&nbsp; Prizes may not be substituted for cash or any other prize, except that Sponsor may in its discretion substitute a prize of equal or greater value.</li>
</ol>
<p>&nbsp;</p>
<ol start="8">
<li><strong><u>8. Errors and Entry Problems</u></strong><strong>: </strong>Sponsor and its directors, officers, employees, contractors, and agents are not responsible for any printing or typographical errors in any materials associated with the Promotion, or for any computer, communication, hardware, software, or network problems or other submission errors or malfunctions that result in late, incomplete, or damaged entries.</li>
</ol>
<p>&nbsp;</p>
<ol start="9">
<li><strong><u>9. Request for Official Rules or Winners List</u></strong><strong>:</strong> To receive a written copy of the names of the Promotion winner(s) and/or these Official Rules, please send your request, along with a self-addressed envelope, postage prepaid, to: Blake Masters for Senate, P.O. Box 13009, Tucson, Arizona 85732-3009. Residents of Vermont or Washington may omit return postage.&nbsp; Requests for the lists of winners must be received after the Sponsor issues the NFTs or notified participants that they may mint the NFTs (which will occur at some point before July 1, 2022) and before July 1, 2023.&nbsp;</li>
</ol>
<p>&nbsp;</p>
<ol start="10">
<li><strong><u>10. General Liability Release</u></strong><strong>: BY ENTERING THIS PROMOTION, ENTRANTS RELEASE SPONSOR AND ITS OFFICERS, DIRECTORS, EMPLOYEES, ADVISORS, CONTRACTORS, AND AGENTS FROM ANY LIABILITY WHATSOEVER AND WAIVE ALL CLAIMS AND CAUSES OF ACTION OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THE PROMOTION, OR THE AWARD, USE OR POSSESSION OF ANY PRIZE OR ANY PORTION OF A PRIZE. </strong></li>
</ol>
<p>&nbsp;</p>
<ol start="11">
<li><strong><u>11. Miscellaneous</u></strong><strong>: </strong>The Promotion and these Official Rules will be governed, construed and interpreted under the laws of the State of Arizona.&nbsp; Entrants agree to be bound by these Official Rules and by the decisions of Sponsor, which are final and binding in all respects.&nbsp; Any provision of these Official Rules deemed unenforceable by a court of competent jurisdiction will be enforced to the extent permissible, and the remainder of these Official Rules will remain in full force and effect. Sponsor reserves the right to change these Official Rules at any time, and to suspend, modify or cancel the Promotion in its sole discretion. Entrants who violate these Official Rules, violate any law, rule or regulation in connection with participation in the Promotion, tamper with the operation of the Promotion or engage in any conduct that is detrimental or unfair to Sponsor, the Promotion or any other entrant (in each case as determined in Sponsor&rsquo;s sole discretion) are subject to disqualification from entry into the Promotion.</li>
</ol>
<p>&nbsp;</p>
<p>All royalties from sales of Zero to One occurring from this Promotion will be donated to the Second Amendment Foundation (SAF), a 501(c)(3) nonprofit organization.</p>
<p>&nbsp;</p>
<ol start="12">
<li><strong><u>12. Sponsor</u></strong><strong>: </strong>Blake Masters for Senate, P.O. Box 13009, Tucson, Arizona 85732-3009.</li>
</ol>
<p>&nbsp;</p>
<ol start="13">
<li><strong><u>13. Contact Information</u></strong><strong>: </strong>Questions regarding the Promotion may be sent via email to NFT@blakemasters.com, or via U.S. mail to: Blake Masters for Senate, P.O. Box 13009, Tucson, Arizona 85732-3009.</li>
</ol>
				<Navigation showHome />

			</Container>
		</React.Fragment>
	)
}

export default Terms;
