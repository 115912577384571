import { useState } from "react"
import classnames from 'classnames';

export default function PageHeadNavigation ({ children, className }) {
  const [showMenu, setShowMenu] = useState(false);

  const openMenu = () => setShowMenu(true);
  const closeMenu = () => setShowMenu(false);

  return (
    <>
      <button type="button" className={classnames("sm:hidden relative z-20 scale-90", {"hidden": showMenu})} onClick={openMenu}>
        <img src="/images/hamburger.svg" alt=""  />
      </button>

      {showMenu && (<div className="fixed w-full h-full z-30 block top-0 left-0 sm:hidden" onClick={closeMenu}></div>)}
      
      <nav
        className={classnames(
          "bg-black/90  space-y-10 sm:space-y-0 justify-center flex-col sm:flex-row sm:bg-opacity-100 text-white sm:bg-transparent sm:text-black fixed sm:relative w-full max-w-menu sm:max-w-full  h-full sm:w-auto sm:h-auto top-0 right-0 p-10 sm:p-0 drop-shadow-xl z-40 sm:flex",
          {
            "hidden": !showMenu,
            "flex": showMenu,
          }
        )}
      >
        <div className="flex sm:hidden justify-center flex-col">
          <button onClick={closeMenu} type="button" className="p-6 hover:bg-black hover:bg-opacity-20 block mx-auto rounded-full">
            <img src="/images/cross.svg" className="mx-auto" alt="" />
          </button>
        </div>

        <p className="text-medium text-center sm:hidden">
            <strong>Zero to one origins NFT: </strong>
            <span>Chapter graphics</span>
        </p>
        
        <ul className="flex items-center justify-center flex-col space-y-10 sm:space-y-0 sm:flex-row list-none sm:space-x-12 max-2-">
          <li><a className="text-base sm:text-medium font-bold sm:font-regular text-white hover:underline" onClick={closeMenu} href="#story">Story</a></li>
          <li><a className="text-base sm:text-medium font-bold sm:font-regular text-white hover:underline" onClick={closeMenu} href="#prizes">Prizes</a></li>
          <li><a className="text-base sm:text-medium font-bold sm:font-regular text-white hover:underline" onClick={closeMenu} href="#collection">Collection</a></li>
          <li><a className="text-base sm:text-medium font-bold sm:font-regular text-white hover:underline" onClick={closeMenu} href="https://v1.ztonft.com" target="_blank" rel="noreferrer">Version 1.0</a></li>
        </ul>
      </nav>
    </>
  )
}

