import { useMemo } from 'react';
import classnames from 'classnames';
export default function NFTSelectorButton({
  selected,
  count,
  price,
  clickHandler,
  black = false
}) {
  const numberFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0,
  maximumFractionDigits: 0, });

  const priceFormatted = useMemo(() => numberFormat.format(price), [price, numberFormat])
  const priceEachFormatted = useMemo(() => `$${Math.floor(price / count)}`, [price, count])

  return (
    <button
      type="button"
      onClick={clickHandler}
      className={classnames(
        'duration-100 ease-in-out cursor-pointer border border-5 py-6 w-auto min-w-selector xl:min-w-0 rounded-button flex flex-col items-center space-y-4 md:space-y-8',
        {
          'border-black': !black,
          'border-white': black,
          'hover:bg-black hover:text-white': !selected && !black,
          'bg-black text-white': selected && !black,
          'hover:bg-white hover:text-black': !selected && black,
          'bg-white text-black': selected && black,
        }
      )}
    >
      {count === 1 ? (
        <span className='text-large font-bold'>1 NFT</span>
      ) : (
        <span className='text-large font-bold'>{count} pack</span>
      )}

      <span className='text-medium md:text-base'>{priceFormatted}</span>
      
      { count > 1 && <span className='text-medium md:text-base italic font-light'>({priceEachFormatted} each)</span> }
    </button>
  )
}


