
import React from 'react';
import Header from '../components/Header'
import Container from '../components/Container'
import Paragraph from '../components/Paragraph'
import Navigation from '../components/Navigation';
import Header3 from '../components/Header3';
import Anchor from '../components/Anchor';

const Privacy = () => {
	return(
		<React.Fragment>
			<Container className="py-24 md:pt-0 text-center">
				<Header>
					Privacy Policy
				</Header>
				
				<Paragraph>Last Updated: July 12, 2021</Paragraph>

				<Paragraph>This Privacy Policy explains how information about you is collected, used and disclosed by Blake Masters for Senate. This Privacy Policy applies to information we collect when you use the websites, mobile sites, and other online services that link to this Privacy Policy (collectively, the &ldquo;Site&rdquo;).</Paragraph>

				<Paragraph>We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice (such as adding a statement to our homepage or sending you an email notification). We encourage you to review the Privacy Policy whenever you access the Site to stay informed about our information practices and the ways you can help protect your privacy.</Paragraph>

				<Header3>Collection of Information</Header3>

				<Header3>Information You Provide to Us</Header3>

				<Paragraph>
					We collect information you provide directly to us. For example, we may collect information when you send us an email, subscribe to receive updates, make a donation, make a purchase, submit content, sign up to get involved, sign a petition, request information or otherwise communicate with us. The types of information we collect may include your name, mailing address, email address, phone number, credit card information and other information you choose to provide.
					<br />In addition, where applicable, the Federal Election Commission (FEC) may require us to collect certain information from donors who donate more than $200 per election cycle, such as your name, mailing address, occupation, and employer.
				</Paragraph>

				<Paragraph>Information We Collect Automatically When You Use the Site</Paragraph>

				<Paragraph>
					When you access or use our Site, we automatically collect information about you, including:
					<br />Log Information: We log information about your use of the Site, including the type of browser you use, access times, pages viewed, your IP address and the page you visited before navigating to our Site.
					<br />Device Information: We may collect information about the computer or mobile device you use to access our Site, including the hardware model, operating system and version, device identifiers and mobile network information.
					<br />Information Collected by Cookies and Other Tracking Technologies: We use various technologies to collect information, and this may include sending cookies to your computer or mobile device. Cookies are small data files stored on your hard drive or in device memory that helps us to improve our Site and your experience, see which areas and features of our Site are popular and count visits. We may also collect information using web beacons (also known as &ldquo;tracking pixels&rdquo; or &ldquo;clear GIFs&rdquo;). Web beacons are electronic images that may be used in our Site or emails and help deliver cookies, count visits, understand usage and campaign effectiveness and determine whether an email has been opened and acted upon. For more information about cookies, and how to disable them, please see &ldquo;Your Choices&rdquo; below.
				</Paragraph>

				<Header3>Information We Collect From Other Sources</Header3>

				<Paragraph>We may also obtain information from other sources and combine that with information we collect through our Site. We may also use such information for list matching and other purposes.</Paragraph>

				<Header3>Use of Information</Header3>

				<Paragraph>
					We may use information about you for various purposes, including to:
					<br />Provide, maintain and improve our Site and send you technical notices, updates, security alerts and support and administrative messages;
					<br />Provide and deliver the information you request, process donations and transactions and send you related information, including confirmations and receipts,
				</Paragraph>

				<Paragraph>Respond to your emails, submissions, comments, questions and requests, provide customer service, request feedback, and otherwise contact you about your use of the Site;</Paragraph>

				<Paragraph>Send you newsletters and otherwise provide you with information or services you request or that we think will be of interest to you, such as sending you information to keep you informed about various campaigns, candidates, issues, events, resources, promotions, contests, products and services;</Paragraph>

				<Paragraph>Help connect you with other supporters, and to solicit volunteers, donations and support for Blake Masters for Senate, issues and organizations that we support;</Paragraph>

				<Paragraph>Comply with applicable Federal elections laws and contact you if other information is necessary under such laws;</Paragraph>

				<Paragraph>Monitor and analyze trends, usage and activities in connection with our Site;</Paragraph>

				<Paragraph>Personalize and improve the Site and provide advertisements, content or features that match user profiles or interests or that are based on the information you provide or the actions you take; and</Paragraph>

				<Paragraph>
					Notify and contact contest or sweepstakes entrants; and
					<br />Carry out any other purpose for which the information was collected.
				</Paragraph>

				<Paragraph>Blake Masters for Senate is based in the United States and the information we collect is governed by U.S. law. By accessing or using the Site or otherwise providing information to us, you consent to the processing and transfer of information in and to the U.S. and other countries.</Paragraph>

				<Header3>Sharing of Information</Header3>

				<Paragraph>We may share information about you as follows or as otherwise described in this Privacy Policy:</Paragraph>

				<Paragraph>
					With vendors, consultants and other service providers or volunteers who need access to such information to carry out work on our behalf;
					<br />With candidates, organizations, campaigns, groups or causes that we believe have similar political viewpoints, principles or objectives or share similar goals and with organizations that facilitate communications and information sharing among such groups;
				</Paragraph>

				<Paragraph>To report required information to the Federal Elections Commission, including name, mailing address, occupation, and name of employers of individuals whose contributions exceed $200 in a election cycle (for additional information, visit the FEC website at http://www.fec.gov);</Paragraph>

				<Paragraph>When we believe in good faith that we are lawfully authorized or required to do so or that doing so is reasonably necessary or appropriate to comply with the law or legal processes or respond to lawful requests, claims or legal authorities, including responding to lawful subpoenas, warrants, or court orders;</Paragraph>

				<Paragraph>If we believe your actions are inconsistent with the spirit or language of our user agreements or policies, or to protect the rights, property and safety of Blake Masters for Senate, its employees, volunteers, constituents or others;</Paragraph>

				<Paragraph>In connection with, or during negotiations of, any reorganization, formation of a successor organization, asset sale or transfer, financing or lending transaction or in any other situation where personal information may be disclosed or transferred as one of the assets of Blake Masters for Senate; and</Paragraph>

				<Paragraph>
					With your consent or at your direction, including if we notify you through our Site that the information you provide will be shared in a particular manner and you provide such information.
					<br />We may also share aggregated or anonymized information that does not directly identify you.
				</Paragraph>

				<Paragraph>The above excludes text messaging originator opt-in data and consent; this information will not be shared with any third parties, provided that the foregoing does not apply to sharing (1) with vendors, consultants and other service providers who need access to such information to carry out work on our behalf (and who will not use such information for their own purposes); (2) if we believe disclosure is required by any applicable law, rule, or regulation or to comply with law enforcement or legal process; and (3) if the user consents to our sharing of such information.</Paragraph>

				<Header3>Online Petitions</Header3>

				<Paragraph>If you sign an online petition, you understand that such petition is public information and that we may make the petition, and your name, city, state, and any comments provided in connection therewith publicly available. In addition, we may provide such petitions or compilations thereof, including your comments, name, city, and state to national, state or local leaders, or to the press.</Paragraph>

				<Header3>Social Sharing Features</Header3>

				<Paragraph>The Site may offer social sharing features and other integrated tools (such as the Facebook &ldquo;Like&rdquo; button), which let you share actions you take on our Site with other media, and vice versa. The use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the entity that provides the social sharing feature. For more information about the purpose and scope of data collection and processing in connection with social sharing features, please visit the privacy policies of the entities that provide these features.</Paragraph>

				<Header3>Advertising and Analytics Services Provided by Others</Header3>

				<Paragraph>We may allow third parties to use cookies, web beacons, or other technologies and to otherwise collect information about you in order to provide analytics, remarketing, conversion tracking, custom audiences, measurement, and advertising services, including serving ads on other sites based on your visits to the Site and other sites across the Internet and across various mobile applications. These entities may collect or receive information about your use of the Site and other websites and mobile applications, including your IP address, browser, device information, pages viewed, time spent on pages, links clicked and conversion information. This information may be used by Blake Masters for Senate and others to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests and better understand your online activity.</Paragraph>

				<Paragraph>For example, we may use Remarketing with Google Analytics and Facebook Custom Audiences, or other remarketing tools to advertise online. This enables third-party vendors, including Google and Facebook, to show our ads on sites across the Internet. Such third-party vendors, may use first-party cookies and third-party cookies together to inform, optimize, and serve ads based on your past visits to our Site. For information on how you can opt out of Google&rsquo;s use of cookies for interest-based ads please visit Google&rsquo;s Ads Settings.</Paragraph>

				<Paragraph>For more information about Internet-based ads, or to opt out of having your web browsing information used for behavioral advertising purposes, please visit www.aboutads.info/choices. For information on how you can opt out of Google&rsquo;s use of cookies for interest-based ads please visit Google&rsquo;s Ads Settings.</Paragraph>

				<Header3>Security</Header3>

				<Paragraph>Blake Masters for Senate takes reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction.</Paragraph>

				<Header3>Your Choices</Header3>

				<Header3>
					Cookies
				</Header3>
				
				<Paragraph>
					Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Site.
				</Paragraph>

				<Header3>Promotional Communications</Header3>

				<Paragraph>You may opt out of receiving text messages, updates and newsletters by following the instructions in those emails or text messages. If you opt out, we may still send you other types of emails, such as those about your use of the Site or any donations or transactions.</Paragraph>

				<Header3>Contact Us</Header3>

				<Paragraph>If you have any questions about this Privacy Policy, please email us at <Anchor href="mailto:info@blakemasters.com">info@blakemasters.com</Anchor></Paragraph>
	
				<Navigation showHome />
			</Container>
		</React.Fragment>
	)
}

export default Privacy;
