import { useCallback, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Anchor from './Anchor'
import Button from './Button'
import Header from './Header'
import NFTSelectorButton from './NFTSelectorButton'
import PageContainer from './PageContainer'
import classnames from 'classnames';
import { isTouchDevice } from '../helpers'

export default function NFTSelector({ black = false, ...properties }) {
  const [selection, setSelection] = useState(8)
  const prices = [
    {
      count: 1,
      price: 200,
      link: 'https://merch.blakemasters.com/shop/p/single-nft-zero-to-one-chapter-graphics',
    },
    {
      count: 3,
      price: 500,
      link: 'https://merch.blakemasters.com/shop/p/3-pack-zero-to-one-chapter-graphics-nfts',
    },
    {
      count: 8,
      price: 1000,
      link: 'https://merch.blakemasters.com/shop/p/8-pack-zero-to-one-chapter-graphics-nfts',
    },
    {
      count: 30,
      price: 2900,
      link: 'https://merch.blakemasters.com/shop/p/30-pack-zero-to-one-chapter-graphics-nfts',
    },
    {
      count: 60,
      price: 5800,
      link: 'https://merch.blakemasters.com/shop/p/60-pack-zero-to-one-chapter-graphics-nfts',
    },
  ]
  const buttonRef = useRef()

  const clickHandler = useCallback((count) => {
    setSelection(count);

    if (!isTouchDevice()) {
      return;
    }

    buttonRef.current.querySelector('a').scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }, [setSelection, buttonRef]);

  const link = useMemo(() => prices.find(p => p.count === selection).link, [selection, prices])


  return (
    <PageContainer className="py-12" {...properties}>
      <Header>
          Bigger pack, better chances
      </Header>

      <div className="flex flex-col items-center pt-8 md:pt-12 scale-90">
        <div className='grid sm:flex xl:grid gap-6 grid-cols-1 xl:grid-cols-5 w-full flex flex-wrap justify-center'>
          {prices.map(({ count, price }) => (
            <NFTSelectorButton
              black={black}
              key={price}
              selected={selection === count}
              count={count}
              price={price}
              clickHandler={() => clickHandler(count)}
            ></NFTSelectorButton>
          ))}
        </div>

        <div className='mt-20' ref={buttonRef}>
          <Button black={black} target="_blank" href={link}>
            {selection === 1 && 'Buy 1 NFT'}
            {selection === 8 && 'Buy an 8 pack'}
            {selection !== 1 && selection !== 8 && `Buy a ${selection} pack`}
          </Button>
        </div>

        <p className='mt-20 text-center'><span className='text-medium md:text-medium-2 font-semibold uppercase'>Max donation $5,800, No purchase necessary, see <Link className={classnames('underline', {
          'text-black hover:text-gray-800': !black,
          'text-white hover:text-gray-100': black,
        })} to="/terms-and-conditions">details, terms, and conditions</Link></span></p>
      </div>
    </PageContainer>
  )
}
